import React, { useState, useEffect } from 'react';
import './Alert.css'; // Asegúrate de incluir los estilos

const Alert = ({ message, duration = 3000, onClose }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = 100;
    const increment = 100 / (duration / interval);

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return oldProgress + increment;
      });
    }, interval);

    const timeout = setTimeout(() => {
      onClose();
    }, duration);

    return () => {
      clearTimeout(timeout);
      clearInterval(timer);
    };
  }, [duration, onClose]);

  return (
    <div className="alert-container">
      <div className="alert-content">
        <p>{message}</p>
        <button className="alert-close-button" onClick={onClose}>Close</button>
      </div>
      <div className="alert-progress-bar">
        <div
          className="alert-progress"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Alert;
